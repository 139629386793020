import { createContext, type ReactNode } from "react";
import { observable } from "mobx";

export const CardWidthContext = createContext<{
  width?: number;
}>(null!);

export const CardWidthProvider = (props: {
  children?: ReactNode;
  cardWidth?: number;
}) => {
  const state = observable(
    {
      width: props.cardWidth,
    },
    {
      width: observable.ref,
    },
  );

  return (
    <CardWidthContext.Provider value={state}>
      {props.children}
    </CardWidthContext.Provider>
  );
};
