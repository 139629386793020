import { z } from "zod";

const withFallback = <T>(schema: z.ZodType<T>, fallback: T) =>
  z.preprocess((value) => {
    const parseResult = schema.safeParse(value);
    if (parseResult.success) return parseResult.data;
    return fallback;
  }, schema);

export const SequenceSchema = z.object({
  from: withFallback(z.number().nonnegative().int(), 0),
  durationInFrames: withFallback(z.number().positive().int(), 1),
});

export type SequenceType = z.infer<typeof SequenceSchema>;

export const AnimationSchema = z.object({
  name: z.string(),
  startFrame: withFallback(z.number().nonnegative().int(), 0),
  durationInFrames: withFallback(z.number().int().positive(), 0),
});
export type AnimationType = z.infer<typeof AnimationSchema>;

export const TransformSchema = z.object({
  x: withFallback(z.number(), 0),
  y: withFallback(z.number(), 0),
  scale: withFallback(z.number().nonnegative(), 0),
  rotate: withFallback(z.number(), 0),
  zIndex: withFallback(z.number().int(), 0),
});
export type TransformType = z.infer<typeof TransformSchema>;

export const CardTypes = z.enum([
  "RAWTEXT",
  "RICHTEXT",
  "IMAGE",
  "VIDEO",
  "AUDIO",
  "CUSTOM",
]);
export type CardType = z.infer<typeof CardTypes>;

export const BaseCardObjectSchema = z.object({
  id: z.string(),
  sequence: SequenceSchema,
  label: z.string(),
  animations: z.array(AnimationSchema),
  transform: TransformSchema,
  width: z.number().optional(),
  isEditable: z.boolean(),
  type: CardTypes,
  metadata: z.unknown(),
});

export type BaseCardObjectType = z.infer<typeof BaseCardObjectSchema>;
