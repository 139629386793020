"use client";

import { useContext, useMemo } from "react";
import { CardWidthContext } from "@/utils/CardWidthProvider";
import { type DeepReadonly } from "@/utils/tsmagic";
import DOMPurify from "isomorphic-dompurify";
import { Sequence } from "remotion";
import { type z } from "zod";

import { type RichTextCardObjectSchema } from "../core/derivedSchema";
import { useCurrentFrameStyle } from "../core/useCurrentFrameStyle";

export type RichTextCardObject = z.infer<typeof RichTextCardObjectSchema>;
export type ReadOnlyRichTextCardObject = DeepReadonly<RichTextCardObject>;

export const RichTextCardObjectComponent: React.FC<{
  cardObject: ReadOnlyRichTextCardObject;
}> = ({ cardObject }) => {
  const { outerStyle, innerStyle } = useCurrentFrameStyle(cardObject);
  const cardWidthContext = useContext(CardWidthContext);
  const width = cardObject.width && cardWidthContext?.width
    ? (cardObject.width / 100) * cardWidthContext.width
    : undefined;
  const html = useMemo(
    () => DOMPurify.sanitize(cardObject.metadata.rawHTML),
    [cardObject.metadata.rawHTML],
  );
  return (
    <Sequence
      from={cardObject.sequence.from}
      durationInFrames={cardObject.sequence.durationInFrames}
      layout="none"
    >
      <div style={outerStyle}>
        <div
          dangerouslySetInnerHTML={{ __html: html }}
          style={{ width: width, ...innerStyle}}
          className="whitespace-nowrap"
        />
      </div>
    </Sequence>
  );
};
